<template>
  <div class="bottom-navbar">
    <div class="margin-content">
      <div class="container px-0">
        <div class="row">
          <div
            class="list-menu-bottom col-12 my-3"
            v-bind:class="{
              'show-dot':
                (type == 0
                  ? listDefault0.length
                  : type == 1
                  ? listDefault1.length
                  : type == 2
                  ? listDefault2.length
                  : listDefault3.length) > 0,
            }"
          >
            <VueSlickCarousel
              v-bind="settingsSlick"
              ref="carousel"
              :dots="true"
              :key="refresh"
            >
              <div
                class="item-menu my-auto text-center"
                style="cursor: pointer"
                v-for="(value, index) in type == 0
                  ? listDefault0
                  : type == 1
                  ? listDefault1
                  : type == 2
                  ? listDefault2
                  : listDefault3"
                :key="index"
                @click="showModal(value)"
              >
                <img
                  v-if="isImage(value.icon)"
                  :src="value.icon"
                  alt=""
                  class="mx-auto img-slick position-relative"
                  style="width: 30px"
                />
                <b-icon v-else :icon="`${value.icon}`" font-scale="2.2" />
                <p class="mb-0 title-menu">{{ value.page_title }}</p>
                <span
                  v-if="
                    value.page_title == 'カート' &&
                    is_login === true &&
                    countListCart > 0
                  "
                  class="sidebar-menu-item-cart position-absolute"
                >
                  {{ countListCart }}
                </span>
                <span
                  v-if="
                    value.page_title == 'コンテンツ一覧' &&
                    (type == 0 || type == 1) &&
                    is_login === true &&
                    countUnread > 0
                  "
                  class="sidebar-menu-item-cart position-absolute"
                >
                  {{ countUnread }}
                </span>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-filter -->
    <div>
      <b-modal
        id="modal-filter"
        no-close-on-backdrop
        hide-header
        hide-footer
        :size="'xl'"
      >
        <div class="d-flex justify-content-between title-modal-filter">
          <div
            class="w-50 custom-tab"
            @click="changeStatusActive('library')"
            :class="{ active: isActiveLibrary }"
          >
            コンテンツ
          </div>
          <div
            class="w-50 custom-tab"
            @click="changeStatusActive('store')"
            :class="{ active: isActiveStore }"
          >
            ショップ
          </div>
        </div>
        <div class="body-modal-filter">
          <label>検索カテゴリ</label>
          <b-form-select
            class="mb-3"
            v-model="idCategory"
            :options="isActiveLibrary ? listCategoryLibrary : listCategoryStore"
            placeholder="アンケートを選んでください"
          ></b-form-select>
          <div
            class="default-layout"
            style="min-height: 0; margin-bottom: 1rem"
          >
            <div class="header-search d-flex align-items-center">
              <b-form-input
                class="search-input"
                placeholder="キーワードを入力してください(任意)"
                v-model="keyword"
              />
              <b-icon class="search-icon" icon="search" />
            </div>
          </div>
          <div style="text-align: right">
            <b-button
              class="mr-3"
              variant="secondary"
              @click="$bvModal.hide('modal-filter')"
            >
              閉じる
            </b-button>
            <b-button class="btn-info" @click="searchContent()">
              <b-spinner
                v-if="isLoading"
                :disabled="isLoading"
                small
              ></b-spinner>
              検索する
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Routes } from "../../utils/routes";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import icon_home from "@/assets/img/icon_home_bottom.svg";
import icon_content_list from "@/assets/img/icon_content_list_bottom.png";
import icon_shop from "@/assets/img/icon_shop_bottom.png";
import icon_menu from "@/assets/img/icon_menu_bottom.png";
import icon_search from "@/assets/img/icon_search_bottom.png";
import icon_cart from "@/assets/img/icon_cart_bottom.jpg";
import icon_back from "@/assets/img/icon_back_bottom.svg";
import icon_chat from "@/assets/img/icon_chat_bottom.jpg";
import icon_next from "@/assets/img/icon_next_bottom.svg";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants";
export default {
  name: "BottomMenu",
  data() {
    return {
      isActiveTabLibrary: false,
      beginUrl: this.$route.params.shopId
        ? "/" + Routes.SHOP + "/" + this.$route.params.shopId
        : "",
      settingsSlick: {
        dots: true,
        infinite: false,
        slidesToShow: Constants.BOTTOM_SLICK,
        slidesToScroll: Constants.BOTTOM_SLICK,
        initialSlide: 0,
        autoplay: false,
        arrows: true,
      },
      type: 0,
      urlNext: "",
      listDefault0: [
        {
          page_title: "ホーム",
          url: this.$router.resolve({ name: "library" }).href,
          icon: icon_home,
          indType: 0,
        },
        {
          page_title: "コンテンツ一覧",
          url: this.$router.resolve({ name: "library" }).href,
          icon: icon_content_list,
          indType: 0,
        },
        {
          page_title: "ショップ",
          url: this.$router.resolve({ name: "store" }).href,
          icon: icon_shop,
          indType: 1,
        },
        {
          page_title: "メニュー",
          url: "",
          icon: icon_menu,
          indType: 0,
        },
        {
          page_title: "検索",
          url: "",
          icon: icon_search,
          indType: 0,
        },
      ],
      listDefault1: [
        {
          page_title: "ホーム",
          url: this.$router.resolve({ name: "library" }).href,
          icon: icon_home,
          indType: 0,
        },
        {
          page_title: "コンテンツ一覧",
          url: this.$router.resolve({ name: "library" }).href,
          icon: icon_content_list,
          indType: 0,
        },
        {
          page_title: "カート",
          url: this.$router.resolve({ name: "default cart" }).href,
          icon: icon_cart,
          indType: 1,
        },
        {
          page_title: "メニュー",
          url: "",
          icon: icon_menu,
          indType: 1,
        },
        {
          page_title: "検索",
          url: "",
          icon: icon_search,
          indType: 1,
        },
      ],
      listDefault2: [
        {
          page_title: "コンテンツ一覧",
          url: this.$router.resolve({ name: "library" }).href,
          icon: icon_content_list,
          indType: 0,
        },
        {
          page_title: "戻る",
          url:
            this.$route.params.idPage && this.$route.params.idPage > 1
              ? this.$router.resolve({
                  name: "DetailPageLibrary",
                  params: {
                    idPage: Number(this.$route.params.idPage - 1),
                    idContent: this.$route.params.idContent,
                  },
                }).href
              : "",
          icon: icon_back,
          indType: 2,
        },
        {
          page_title: "ノート/質問",
          url: "",
          icon: icon_chat,
          indType: 2,
        },
        {
          page_title: "次へ",
          url: "",
          icon: icon_next,
          indType: 2,
        },
        {
          page_title: "目次",
          url: "",
          icon: icon_menu,
          indType: 2,
        },
      ],
      listDefault3: [
        {
          page_title: "コンテンツ一覧",
          url: this.$router.resolve({ name: "library" }).href,
          icon: icon_content_list,
          indType: 0,
        },
        {
          page_title: "ショップ",
          url: this.$router.resolve({ name: "store" }).href,
          icon: icon_shop,
          indType: 1,
        },
        {
          page_title: "メニュー",
          url: "",
          icon: icon_menu,
          indType: 3,
        },
        {
          page_title: "検索",
          url: "",
          icon: icon_search,
          indType: 3,
        },
      ],
      checkPush0: false,
      checkPush1: false,
      checkPush2: false,
      checkPush3: false,
      dataMenu1: [],
      countListCart: 0,
      countUnread: 0,
      is_login:
        localStorage.getItem(Constants.TOKEN_USER) ||
        localStorage.getItem(Constants.TOKEN_ADMIN)
          ? true
          : false,
      refresh: 0,
      inquiry_URL: "",
      formCategory: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        page: 1,
      },
      idCategory: 0,
      isActiveLibrary: false,
      isActiveStore: true,
      keyword: "",
      listCategoryStore: [{ text: "すべて", value: 0 }],
      listCategoryLibrary: [{ text: "すべて", value: 0 }],
      isLoading: false,
      listPage: [],
    };
  },
  components: { VueSlickCarousel },
  async created() {
    if (this.$route.params.idPage) this.type = 2;
    else if (this.$route.name.includes("library")) this.type = 0;
    else if (this.$route.fullPath.includes("store")) this.type = 1;
    else this.type = 3;
    this.refresh++;
    let shopId = this.$route.params.shopId;
    let body = {
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId || null,
    };
    let response = await this.$store.dispatch("getShopById", body);
    shopId = response.data.id;
    const request = {
      shop_id: shopId,
      type: this.type != null ? this.type : 3,
    };
    this.getUnreadContent(request);
    // this.getBottomSettingType(request);
    if (this.$route.params.idContent) {
      const reqData = {
        id: this.$route.params.idContent,
        shop_id: this.$route.params.shopId,
      };
      this.getContentLibraryById(reqData);
    }
    this.getListSettingPageHeader(shopId);
    this.getListBottom(request);
    this.getListSettingShopUser({
      domain: Constants.DOMAIN,
      shop_id: shopId,
    });
    this.getListCategoryInStore(this.formCategory);
    this.getListLibraryCategory(this.formCategory);
  },
  computed: {
    ...mapGetters([
      "listBottom",
      "contentLibraryById",
      "listSettingPageHeader",
      "listCart",
      "showMenu",
      "countContentUnread",
      "message",
      "success",
      "error",
      "isShowButtonChatOrNote",
      "ListSettingShop",
      "listCategory",
      "listLibraryCategory",
    ]),
  },
  watch: {
    listLibraryCategory() {
      const self = this;
      if (Object.keys(this.listLibraryCategory).length > 0) {
        this.listLibraryCategory.data.forEach((item) => {
          if (
            self.listCategoryLibrary.length <
            self.listLibraryCategory.data.length + 1
          ) {
            self.listCategoryLibrary.push({
              text: item.category_name,
              value: item.category_id,
            });
          }
        });
      }
    },
    listCategory() {
      const self = this;
      if (Object.keys(this.listCategory).length > 0) {
        this.listCategory.data.forEach((item) => {
          if (
            self.listCategoryStore.length <
            self.listCategory.data.length + 1
          ) {
            self.listCategoryStore.push({
              text: item.category_name,
              value: item.category_id,
            });
          }
        });
      }
    },
    countContentUnread() {
      this.countUnread = this.countContentUnread;
    },
    listBottom() {
      if (
        this.listBottom &&
        this.listBottom.length > 0 &&
        !this["checkPush" + this.type]
      ) {
        this.listBottom.forEach((element) => {
          var item = {
            page_title: element.page_title,
            url: this.beginUrl + element.url,
            icon: element.icon,
            indType: 3,
            type: element.type,
            fixed_page_id: element.fixed_page_id,
          };
          if (item.type == this.type)
            this["listDefault" + this.type].push(item);
        });
      }
      this.$refs.carousel.goTo(0);
      this.refresh++;
      if (this["listDefault" + this.type].length < Constants.BOTTOM_SLICK) {
        this.settingsSlick.slidesToShow =
          this["listDefault" + this.type].length;
        this.settingsSlick.slidesToScroll =
          this["listDefault" + this.type].length;
      } else {
        this.settingsSlick.slidesToShow = Constants.BOTTOM_SLICK;
        this.settingsSlick.slidesToScroll = Constants.BOTTOM_SLICK;
      }
      this["checkPush" + this.type] = true;
    },

    contentLibraryById() {
      this.listPage = [];
      // const arrayPage = [];
      if (this.contentLibraryById && this.contentLibraryById.length > 0) {
        this.dataCata = this.contentLibraryById[0].catalogue.filter(
          (item) => item.id == this.idCata
        );
        this.listCata = this.contentLibraryById[0].catalogue;

        // this.idPageNext = Number(this.idCata) + 1;
        // this.idPagePrev = Number(this.idCata) - 1;
        this.contentLibraryById[0].catalogue.forEach((element) => {
          const objectPage = {
            idChapter: null,
            page: [],
          };
          element.page.forEach((item) => {
            objectPage.page.push(item.id);
          });
          objectPage.idChapter = element.catalogue_id;
          this.listPage.push(objectPage);
        });
        if (
          this.contentLibraryById &&
          this.contentLibraryById.length > 0 &&
          this.contentLibraryById[0].catalogue.length > 0
        ) {
          //next
          // if (
          //   this.$route.params.idPage &&
          //   this.$route.params.idPage <
          //     this.contentLibraryById[0].catalogue.length
          // ) {
          //   this.listDefault2[3].url = this.$router.resolve({
          //     name: "DetailPageLibrary",
          //     params: {
          //       idPage: Number(this.$route.params.idPage) + 1,
          //       idContent: this.$route.params.idContent,
          //     },
          //   }).href;
          // }
          const ChapterId = parseInt(this.$route.params.idChapter);
          this.listPage.forEach((element, index) => {
            if (element.idChapter === ChapterId) {
              const indexpage = element.page.indexOf(
                parseInt(this.$route.params.idPage)
              );
              if (
                indexpage === element.page.length - 1 &&
                index === this.listPage.length - 1
              ) {
                this.listDefault2[3].url = this.$router.resolve({
                  name: "DetailPageLibrary",
                  params: {
                    idPage: this.listPage[0].page[0],
                    idContent: this.$route.params.idContent,
                    idChapter: this.listPage[0].idChapter,
                  },
                }).href;
              } else if (
                indexpage === element.page.length - 1 &&
                index !== this.listPage.length - 1
              ) {
                const indexChapter = index + 1;
                this.listDefault2[3].url = this.$router.resolve({
                  name: "DetailPageLibrary",
                  params: {
                    idPage: this.listPage[indexChapter].page[0],
                    idContent: this.$route.params.idContent,
                    idChapter: this.listPage[indexChapter].idChapter,
                  },
                }).href;
              } else {
                const page = indexpage + 1;
                this.listDefault2[3].url = this.$router.resolve({
                  name: "DetailPageLibrary",
                  params: {
                    idPage: element.page[page],
                    idContent: this.$route.params.idContent,
                    idChapter: element.idChapter,
                  },
                }).href;
              }
            }
          });
          if (this.$route.params.idPage && this.$route.params.idPage > 0) {
            // this.listDefault2[1].url = this.$router.resolve({
            //   name: "DetailPageLibrary",
            //   params: {
            //     idPage: Number(this.$route.params.idPage) - 1,
            //     idContent: this.$route.params.idContent,
            //   },
            // }).href;
            const ChapterId = parseInt(this.$route.params.idChapter);
            this.listPage.forEach((element, index) => {
              if (element.idChapter === ChapterId) {
                const indexpage = element.page.indexOf(
                  parseInt(this.$route.params.idPage)
                );
                if (indexpage === 0 && index === 0) {
                  const IndexChapterMax = this.listPage.length - 1;
                  const inddexPageMax =
                    this.listPage[IndexChapterMax].page[
                      this.listPage[IndexChapterMax].page.length - 1
                    ];
                  // console.log(
                  //   "IndexChapterMax",
                  //   this.listPage[IndexChapterMax].idChapter
                  // );
                  // console.log("inddexPageMax", inddexPageMax);
                  this.listDefault2[1].url = this.$router.resolve({
                    name: "DetailPageLibrary",
                    params: {
                      idPage: inddexPageMax,
                      idContent: this.$route.params.idContent,
                      idChapter: this.listPage[IndexChapterMax].idChapter,
                    },
                  }).href;
                } else if (indexpage === 0 && index !== 0) {
                  const indexChapter = index - 1;
                  const indexpage = this.listPage[indexChapter].page.length - 1;
                  this.listDefault2[1].url = this.$router.resolve({
                    name: "DetailPageLibrary",
                    params: {
                      idPage: this.listPage[indexChapter].page[indexpage],
                      idContent: this.$route.params.idContent,
                      idChapter: this.listPage[indexChapter].idChapter,
                    },
                  }).href;
                } else {
                  const page = indexpage - 1;
                  this.listDefault2[1].url = this.$router.resolve({
                    name: "DetailPageLibrary",
                    params: {
                      idPage: element.page[page],
                      idContent: this.$route.params.idContent,
                      idChapter: element.idChapter,
                    },
                  }).href;
                }
              }
            });
          }
        }
      }
    },
    listSettingPageHeader() {
      this.dataMenu1 = this.listSettingPageHeader;
    },
    listCart() {
      this.countListCart = this.listCart.length;
      // this.listCart.forEach((element) => {
      //   element.list_cart.forEach(() => {
      //     this.countListCart += 1;
      //   });
      // });
    },
    $route() {
      if (this.$route.params.idPage) this.type = 2;
      else if (this.$route.name.includes("library")) this.type = 0;
      else if (this.$route.fullPath.includes("store")) this.type = 1;
      else this.type = 3;
      this.$refs.carousel.goTo(0);
      this.refresh++;
      if (this["listDefault" + this.type].length < 5) {
        this.settingsSlick.slidesToShow =
          this["listDefault" + this.type].length;
        this.settingsSlick.slidesToScroll =
          this["listDefault" + this.type].length;
      } else {
        this.settingsSlick.slidesToShow = 5;
        this.settingsSlick.slidesToScroll = 5;
      }
    },
    ListSettingShop() {
      if (this.ListSettingShop.length > 0) {
        this.inquiry_URL = this.ListSettingShop[0].url_qa;
      }
    },
  },
  methods: {
    ...mapActions({
      // getBottomSettingType: "getBottomSettingType",
      getContentLibraryById: "getContentLibraryById",
      getListSettingPageHeader: "getListSettingPageHeader",
      getListCart: "getListCart",
      getUnreadContent: "getUnreadContent",
      getListBottom: "getListBottom",
      getListSettingShopUser: "getListSettingShopUser",
      getListCategoryInStore: "getListCategoryInStore",
      getListLibraryCategory: "getListLibraryCategory",
    }),
    isImage(url) {
      return /\.(jpg|jpeg|png|svg|JPG|JPEG|PNG)$/.test(url);
    },
    returnPageNote(value) {
      if (value.indType == null) value.indType = 3;
      this.type = value.indType;
      const request = {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        type: this.type,
      };
      // this.getBottomSettingType(request);
      this.getListBottom(request);
      this.getUnreadContent(request);
      this.$refs.carousel.goTo(0);
      this.refresh++;
      if (this["listDefault" + this.type].length <= Constants.BOTTOM_SLICK) {
        this.settingsSlick.slidesToShow =
          this["listDefault" + this.type].length;
        this.settingsSlick.slidesToScroll =
          this["listDefault" + this.type].length;
      } else {
        this.settingsSlick.slidesToShow = Constants.BOTTOM_SLICK;
        this.settingsSlick.slidesToScroll = Constants.BOTTOM_SLICK;
      }
      if (value.page_title == "メニュー") {
        this.$store.commit("set", ["showMenu", !this.showMenu]);
      } else {
        // if (value.type && value.type == 5) {
        //   this.$router.push({
        //     name: "pageColumn",
        //     params: {
        //       shopId: localStorage.getItem(Constants.SHOP_ID),
        //       id: value.fixed_page_id,
        //     },
        //   });
        // } else {
        if (value.page_title == "お問い合わせ") {
          if (this.inquiry_URL) {
            window.location.href = this.inquiry_URL;
          } else {
            if (this.$route.params.shopId) {
              this.$router.push({
                name: "create inquiry user",
                params: { shopId: this.$route.params.shopId },
              });
            } else {
              this.$router.push({
                name: "create inquiry user domain",
              });
            }
          }
        } else {
          this.$router.push(value.url);
        }
        // }
        this.$store.commit("set", ["showMenu", false]);
      }
      if (value.page_title === "ノート/質問") {
        this.$store.commit("set", [
          "isShowButtonChatOrNote",
          !this.isShowButtonChatOrNote,
        ]);
      }
    },
    changeStatusActive(active) {
      if (active === "store") {
        this.isActiveStore = true;
        this.isActiveLibrary = false;
      } else if (active === "library") {
        this.isActiveStore = false;
        this.isActiveLibrary = true;
      }
    },
    showModal(value) {
      if (value.page_title === "検索") {
        this.$bvModal.show("modal-filter");
      } else {
        this.returnPageNote(value);
      }
    },
    searchContent() {
      this.isLoading = true;
      const shop_id = this.$route.params.shopId;
      const self = this;
      const formData = {
        keyword: this.keyword,
        author_id: "",
        category_id: this.idCategory,
        shop_id: shop_id,
        is_search_favorite: "",
        is_search_recent_content: "",
        is_search_new_content: "",
        limit: "8",
      };
      if (this.isActiveLibrary) {
        Api.userRequestServer
          .post(`/${Urls.LIBRARY}/${Urls.FILTER}`, formData)
          .then((response) => {
            const { data } = response;
            const keyword = self.keyword;
            if (data.success) {
              self.$bvModal.hide("modal-filter");
              self.isLoading = false;
              self.$router
                .push({
                  name: "library",
                  params: {
                    shopId: shop_id,
                  },
                  query: {
                    keyword: self.keyword,
                  },
                })
                .catch(() => {
                  self.$router
                    .push({
                      name: "store",
                    })
                    .then(() => {
                      self.$router.push({
                        name: "library",
                        params: {
                          shopId: shop_id,
                        },
                        query: {
                          keyword: keyword,
                        },
                      });
                    });
                });
              self.keyword = "";
            } else {
              self.$bvModal.hide("modal-filter");
              self.isLoading = false;
              self.$toasted.error(data.message);
            }
          })
          .catch((error) => {
            self.$bvModal.hide("modal-filter");
            self.isLoading = false;
            self.$toasted.error(error.message);
          });
      } else {
        Api.userRequestServer
          .post(`/${Urls.STORE}/${Urls.FILTER}`, formData)
          .then((response) => {
            const { data } = response;
            const keyword = self.keyword;
            if (data.success) {
              self.$bvModal.hide("modal-filter");
              self.isLoading = false;
              self.$router
                .push({
                  name: "store",
                  params: {
                    shopId: shop_id,
                  },
                  query: {
                    keyword: self.keyword,
                  },
                })
                .catch(() => {
                  self.$router
                    .push({
                      name: "library",
                    })
                    .then(() => {
                      self.$router.push({
                        name: "store",
                        params: {
                          shopId: shop_id,
                        },
                        query: {
                          keyword: keyword,
                        },
                      });
                    });
                });
              self.keyword = "";
            } else {
              self.$bvModal.hide("modal-filter");
              self.isLoading = false;
              self.$toasted.error(data.message);
            }
          })
          .catch((error) => {
            self.$bvModal.hide("modal-filter");
            self.isLoading = false;
            self.$toasted.error(error.message);
          });
      }
    },
    NextPage() {
      const ChapterId = parseInt(this.$route.params.idChapter);
      this.listPage.forEach((element, index) => {
        if (element.idChapter === ChapterId) {
          const indexpage = element.page.indexOf(
            parseInt(this.$route.params.idPage)
          );
          if (
            indexpage === element.page.length - 1 &&
            index === this.listPage.length - 1
          ) {
            this.$router.push({
              name: "DetailPageLibrary",
              params: {
                idPage: this.listPage[0].page[0],
                idContent: this.$route.params.idContent,
                idChapter: this.listPage[0].idChapter,
              },
            });
          } else if (
            indexpage === element.page.length - 1 &&
            index !== this.listPage.length - 1
          ) {
            const indexChapter = index + 1;
            this.$router.push({
              name: "DetailPageLibrary",
              params: {
                idPage: this.listPage[indexChapter].page[0],
                idContent: this.$route.params.idContent,
                idChapter: this.listPage[indexChapter].idChapter,
              },
            });
          } else {
            const page = indexpage + 1;
            this.$router.push({
              name: "DetailPageLibrary",
              params: {
                idPage: element.page[page],
                idContent: this.$route.params.idContent,
                idChapter: element.idChapter,
              },
            });
          }
        }
      });
    },
    PrevPage() {
      const ChapterId = parseInt(this.$route.params.idChapter);
      this.listPage.forEach((element, index) => {
        if (element.idChapter === ChapterId) {
          const indexpage = element.page.indexOf(
            parseInt(this.$route.params.idPage)
          );
          if (indexpage === 0 && index === 0) {
            const IndexChapterMax = this.listPage.length - 1;
            const inddexPageMax =
              this.listPage[IndexChapterMax].page[
                this.listPage[IndexChapterMax].page.length - 1
              ];
            // console.log(
            //   "IndexChapterMax",
            //   this.listPage[IndexChapterMax].idChapter
            // );
            // console.log("inddexPageMax", inddexPageMax);
            this.$router.push({
              name: "DetailPageLibrary",
              params: {
                idPage: inddexPageMax,
                idContent: this.$route.params.idContent,
                idChapter: this.listPage[IndexChapterMax].idChapter,
              },
            });
          } else if (indexpage === 0 && index !== 0) {
            const indexChapter = index - 1;
            const indexpage = this.listPage[indexChapter].page.length - 1;
            this.$router.push({
              name: "DetailPageLibrary",
              params: {
                idPage: this.listPage[indexChapter].page[indexpage],
                idContent: this.$route.params.idContent,
                idChapter: this.listPage[indexChapter].idChapter,
              },
            });
          } else {
            const page = indexpage - 1;
            this.$router.push({
              name: "DetailPageLibrary",
              params: {
                idPage: element.page[page],
                idContent: this.$route.params.idContent,
                idChapter: element.idChapter,
              },
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
// .modal-body {
//   padding: 0 !important;
// }
.title-modal-filter {
  height: 50px;
  align-items: center;
  background: #02bad2;
  div {
    text-align: center;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.body-modal-filter {
  padding: 1rem;
}
.custom-tab {
  margin-bottom: 0;
  position: relative;
}
.custom-tab::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  // background-color: #f55747;
}
.custom-tab:hover:after {
  transform: scaleX(1);
  transition: transform 0.25s ease-out;
}
.custom-tab.active::after {
  transform: scaleX(1);
}
.version-desktop {
  display: none;
}
.default-layout {
  min-height: calc(100vh - 80px);
  margin-bottom: 80px;
}
.default-layout .content {
  min-height: calc(100vh - 180px);
}
.footer {
  // padding-bottom: 80px!important;
  .footer-copyright {
    margin: 15px 0 15px;
  }
}
.bottom-navbar {
  bottom: 0px;
  background: #f9f9f9;
  width: 100%;
  height: 80px;
  .list-menu-bottom {
    height: 80px;
    .item-menu {
      img {
        width: 30px;
      }
      .title-menu {
        font-size: 10px;
      }
      .sidebar-menu-item-cart {
        top: 0;
        width: 16px;
        height: 16px;
        background: orangered;
        font-size: 11px;
        border-radius: 50%;
        text-align: center;
      }
      a {
        color: #888f9e;
      }
    }
    .slick-slider {
      height: 80px;
      // .slick-list
    }
  }
  .show-dot {
    .slick-dots {
      bottom: 0 !important;
      margin: revert;
    }
  }
}
.list-menu-sp {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(100%);
  z-index: 1031;
  height: 100vh;
  width: 100vw;
  transition: all 0.5s;
  display: none;
  flex-flow: column;
  flex-shrink: 1;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.1);
  .sidebar-menu {
    width: 80vw;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
  }
  .sidebar-item {
    padding: 15px 20px 15px 0px;
    font-size: 15px;
    border-radius: 10px;
    color: #fff;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    svg {
      font-size: 20px;
      // opacity: 35% !important;
    }
    .sidebar-menu-item-cart {
      height: 20px;
      width: 20px;
      background: orangered;
      font-size: 12px;
      border-radius: 50%;
      text-align: center;
      top: 10%;
      left: 10%;
      // opacity: 35%;
    }
  }
}
.list-menu-sp.active {
  transform: translateX(0);
  display: flex;
}
</style>
