var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-navbar"},[_c('div',{staticClass:"margin-content"},[_c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"list-menu-bottom col-12 my-3",class:{
            'show-dot':
              (_vm.type == 0
                ? _vm.listDefault0.length
                : _vm.type == 1
                ? _vm.listDefault1.length
                : _vm.type == 2
                ? _vm.listDefault2.length
                : _vm.listDefault3.length) > 0,
          }},[_c('VueSlickCarousel',_vm._b({key:_vm.refresh,ref:"carousel",attrs:{"dots":true}},'VueSlickCarousel',_vm.settingsSlick,false),_vm._l((_vm.type == 0
                ? _vm.listDefault0
                : _vm.type == 1
                ? _vm.listDefault1
                : _vm.type == 2
                ? _vm.listDefault2
                : _vm.listDefault3),function(value,index){return _c('div',{key:index,staticClass:"item-menu my-auto text-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showModal(value)}}},[(_vm.isImage(value.icon))?_c('img',{staticClass:"mx-auto img-slick position-relative",staticStyle:{"width":"30px"},attrs:{"src":value.icon,"alt":""}}):_c('b-icon',{attrs:{"icon":`${value.icon}`,"font-scale":"2.2"}}),_c('p',{staticClass:"mb-0 title-menu"},[_vm._v(_vm._s(value.page_title))]),(
                  value.page_title == 'カート' &&
                  _vm.is_login === true &&
                  _vm.countListCart > 0
                )?_c('span',{staticClass:"sidebar-menu-item-cart position-absolute"},[_vm._v(" "+_vm._s(_vm.countListCart)+" ")]):_vm._e(),(
                  value.page_title == 'コンテンツ一覧' &&
                  (_vm.type == 0 || _vm.type == 1) &&
                  _vm.is_login === true &&
                  _vm.countUnread > 0
                )?_c('span',{staticClass:"sidebar-menu-item-cart position-absolute"},[_vm._v(" "+_vm._s(_vm.countUnread)+" ")]):_vm._e()],1)}),0)],1)])])]),_c('div',[_c('b-modal',{attrs:{"id":"modal-filter","no-close-on-backdrop":"","hide-header":"","hide-footer":"","size":'xl'}},[_c('div',{staticClass:"d-flex justify-content-between title-modal-filter"},[_c('div',{staticClass:"w-50 custom-tab",class:{ active: _vm.isActiveLibrary },on:{"click":function($event){return _vm.changeStatusActive('library')}}},[_vm._v(" コンテンツ ")]),_c('div',{staticClass:"w-50 custom-tab",class:{ active: _vm.isActiveStore },on:{"click":function($event){return _vm.changeStatusActive('store')}}},[_vm._v(" ショップ ")])]),_c('div',{staticClass:"body-modal-filter"},[_c('label',[_vm._v("検索カテゴリ")]),_c('b-form-select',{staticClass:"mb-3",attrs:{"options":_vm.isActiveLibrary ? _vm.listCategoryLibrary : _vm.listCategoryStore,"placeholder":"アンケートを選んでください"},model:{value:(_vm.idCategory),callback:function ($$v) {_vm.idCategory=$$v},expression:"idCategory"}}),_c('div',{staticClass:"default-layout",staticStyle:{"min-height":"0","margin-bottom":"1rem"}},[_c('div',{staticClass:"header-search d-flex align-items-center"},[_c('b-form-input',{staticClass:"search-input",attrs:{"placeholder":"キーワードを入力してください(任意)"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('b-icon',{staticClass:"search-icon",attrs:{"icon":"search"}})],1)]),_c('div',{staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-3",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$bvModal.hide('modal-filter')}}},[_vm._v(" 閉じる ")]),_c('b-button',{staticClass:"btn-info",on:{"click":function($event){return _vm.searchContent()}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"disabled":_vm.isLoading,"small":""}}):_vm._e(),_vm._v(" 検索する ")],1)],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }